import React from "react";
import About from "../subpage_src/components/About";
import infoPic from "../subpage_src/images/about.jpg";
import data from '../data/Data';
import Layout from "../subpage_src/components/Layout";

const AboutPage = () => {
    return (
        <Layout lang={"cn"}>
            <About
                lang={"cn"}
                infoPic = {infoPic}
                about = {data.about}
            />
        </Layout>
    )
}

export default AboutPage;